import { createStore } from 'vuex'

export default createStore({
state: {
    usertname: 'null',
    lastName: 'Doe',
  },
mutations: {
  addphone(state, user) {
    state.phone = user
  }
},
actions: {

    
},
getters: {

}
})